import React from "react";

import PageWrapper from "../components/PageWrapper";
import Content from "../sections/career/Content";
import Feature from "../sections/career/Feature";
import Roles from "../sections/career/Roles";
import CTA from "../sections/landing1/CTARow";
import { RichText } from 'prismic-reactjs'
import SEO from "../components/seo";
import styled from "styled-components";

import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text } from "../components/Core";

const StyledText = styled(Text)`

p{
    margin-bottom: 1rem !important;
}

`

const Career = ({data}) => {
  const document = data.allPrismicBlogPost.edges[0].node

  return (
    <>
      <PageWrapper>
      <SEO title={document.data.title.text} description={document.data.description.text}/>

        <Section bg='#C3E9D8'>
        <Container>
          <Row className="pb-5 mb-5 mt-5">
            <Col>
            <Title color='white' variant='hero' className="mb-4">
            {document.data.title.text}
            </Title>
            <Text color='white' className='pb-2 ml-2'>
                Posted on: {document.first_publication_date} by Jane Saunders
            </Text>
            
            <div className='mb-4'>
                <img className='w-100' src={document.data.hero_image.url}></img>
            </div>

            <StyledText>
            <div dangerouslySetInnerHTML={{ __html: document.data.post_content.html}}/>
            </StyledText>

            </Col>
          </Row>
        </Container>
      </Section>
      </PageWrapper>
    </>
  );
};

export const query = graphql`
query PageQuery($uid: String) {
    allPrismicBlogPost(filter: {uid: {eq: $uid}}) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            post_content {
              text
              html
            }
            hero_image {
              url
              alt
            }
            description {
              text
            }
          }
          first_publication_date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
  `

export default Career;

