import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";

import { Title, Section, Box, Text, Badge } from "../../components/Core";

import GlobalContext from "../../context/GlobalContext";
import { device } from "../../utils";

import imgVideo from "../../assets/jane/opacity.jpg";
import { Link } from "gatsby";
import { RichText } from 'prismic-reactjs'

const IconButton = styled(Box)`
  cursor: pointer;
  font-size: 22px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: ${({ theme }) => theme.colors.light};
  width: 64px;
  height: 64px;
  @media ${device.sm} {
    width: 100px;
    height: 100px;
  }
  @media ${device.md} {
    width: 124px;
    height: 124px;
  }
`;

const Card = styled(Link)`
  justify-content: flex-start;
  min-height: 100%;
  box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
  background-color: #3C5664;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
  transition: 0.4s;
  border-radius: 10px;
  &:hover {
    box-shadow: 0 32px 84px rgba(14, 86, 124, 0.17);
    color: #0056b3 !important;
    text-decoration: none;
    .title {
      color: #0056b3 !important;
    }
  }
`;

const Location = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const BadgeStyled = styled(Badge)`
  background-color: ${({ theme, bg }) => rgba(theme.colors[bg], 0.15)};
  color: ${({ theme, bg }) => theme.colors[bg]};
  border: none;
  font-weight: 300;
`;


const Content = ({ data }) => {
  const gContext = useContext(GlobalContext);

  const openVideoModal = (e) => {
    e.preventDefault();
    gContext.toggleVideoModal();
  };

  return (
    <>
      <Section bg='#C3E9D8'>
        <Container>
          <Row className="pb-5 mb-5 mt-5">
            <Col>
            <Title color='white' variant='hero' className="mb-4">
            {data.title.text}
            </Title>

              {data.services_copy.map((item, index) => {
                return (
                  <>
                    <Title color='white' className="mb-2">
                      {item.title.text}
                    </Title>

                    <Text style={{whiteSpace: "pre-wrap"}} color='#507891' className="mb-4">

                  {item.service_block_text.text}

                    </Text>
                  </>
                )
              })}

            </Col>
          </Row>
        </Container>
      </Section>

    </>
  );
};


export default Content;
